import React from "react";

import DummyWrapper from "../../hoc/DummyWrapper";

import classes from "./Navbar.module.css";

const navbar = () => {
    return (
        <DummyWrapper>
            <nav>
                <ul>
                    <a href="/">
                        <li>Home</li>
                    </a>
                    <a href="/news">
                        <li>Știri</li>
                    </a>
                    <a href="/opinions">
                        <li>Opinii</li>
                    </a>
                    <a href="/podcast">
                        <li>Podcast</li>
                    </a>
                    <a href="/onthefield">
                        <li>#peteren</li>
                    </a>
                    <a href="https://paulangelescu.ro/contact-form">
                        <li>Contact</li>
                    </a>
                </ul>
                <div className="handle">Menu</div>
            </nav>
        </DummyWrapper>
    );
};

export default navbar;
