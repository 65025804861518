import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyCHu6IsfvyoPx97xILzIc2xwDikeC0W_-o",
    authDomain: "paul-angelescu-website.firebaseapp.com",
    projectId: "paul-angelescu-website",
    storageBucket: "paul-angelescu-website.appspot.com",
    messagingSenderId: "818972131151",
    appId: "1:818972131151:web:5db0efb4fc6dc0b7bccf3a",
    measurementId: "G-HXLEFJH66L"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

//DATABASE CACHING
firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
        if (err.code === "failed-precondition") {
            console.log("failed to enable caching: failed-precondition");
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code === "unimplemented") {
            console.log("failed to enable caching: unimplemented");
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });
// Subsequent queries will use persistence, if it was enabled successfully

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export default firebase;

//npm install firebase !!!
