import React from "react";
import {BrowserRouter} from "react-router-dom";

import RequestHandler from "./RequestHandler/RequestHandler";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import './App.css';

function App() {
    return (
        <BrowserRouter basename="/">
            <React.Fragment>
                <Navbar/>
                <RequestHandler/>
                <Footer/>
            </React.Fragment>
        </BrowserRouter>
    );
}

export default App;
