import React, {Component, Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import Loader1 from "../Components/Loaders/Loader1/Loader1";
import Home from "../Pages/Home/Home"
import Article from "../Pages/Article/Article"

class RequestHandler extends Component {
    render() {
        const About = React.lazy(() => import("../Pages/About/About"));
        const PrivacyPolicy = React.lazy(() => import("../Pages/Legal/PrivacyPolicy"));
        const License = React.lazy(() => import("../Pages/Legal/License"));
        const Disclaimer = React.lazy(() => import("../Pages/Disclaimer/Disclaimer"));
        const Login = React.lazy(() => import("../Pages/Login/Login"));
        const AddNewArticle = React.lazy(() => import("../Pages/AddNewArticle/AddNewArticle"));
        const EditArticle = React.lazy(() => import("../Pages/EditArticle/EditArticle"));

        return (
            <Suspense fallback={<Loader1/>}>
                <Switch>
                    <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
                    <Route path="/license" exact component={License}/>
                    <Route path="/disclaimer" exact component={Disclaimer}/>
                    <Route path="/about" exact component={About}/>
                    <Route path="/admin/login" exact component={Login}/>
                    <Route path="/admin/new-article" exact component={AddNewArticle}/>
                    <Route path="/admin/edit-article/:article_id" exact component={EditArticle}/>
                    <Route path="/articles/:article_id*" exact component={Article}/>
                    <Route path="/article/:article_id*" exact component={Article}/>
                    <Route path="/" exact component={Home}/>
                    {/*<Route path="/home" exact component={MainFeed}/>*/}
                </Switch>
            </Suspense>
        );
    }
}

export default RequestHandler;