import React from "react";
import {Link, NavLink} from "react-router-dom";

import classes from "./Footer.module.css";

const footer = () => {
    return (
        <React.Fragment>
            <footer className={classes.footer}>
                <div className={classes.inner_footer}>
                    <div className={classes.logo_container}>
                        <img src="/logo512.png" alt="The logo of the website."/>
                    </div>
                    <div className={classes.footer_third}>
                        <h1>Despre</h1>
                        <Link to="/about">PAUL ANGELESCU</Link>
                    </div>
                    <div className={classes.footer_third}>
                        <h1>Social</h1>
                        <Link to={{pathname: "https://www.facebook.com/paul.angelescu.1"}} target="_blank">
                            FACEBOOK
                        </Link>
                        <Link to={{pathname: "https://www.instagram.com/paul_angelescu/"}} target="_blank">
                            INSTAGRAM
                        </Link>
                        {/*TODO REPLACE WITH YOUTUBE LINK*/}
                        <Link to={{pathname: ""}}
                              target="_blank">
                            YOUTUBE
                        </Link>
                    </div>
                    <div className={classes.footer_third}>
                        <h1>Contact</h1>
                        <Link to="https://paulangelescu.ro/contact-form">TRIMITE UN MESAJ</Link>
                        <Link to="/">contact@codingshadows.com</Link>
                    </div>
                </div>
            </footer>
            <div className={classes.footer_copyright}>
                <NavLink to="/license" className={classes.link} activeClassName={classes.activeLink}>
                    License
                </NavLink>
                <NavLink to="/disclaimer" className={classes.link} activeClassName={classes.activeLink}>
                    Disclaimer
                </NavLink>
                <NavLink to="/privacy-policy" className={classes.link} activeClassName={classes.activeLink}>
                    Privacy Policy
                </NavLink>
            </div>
        </React.Fragment>
    );
};

export default footer;
