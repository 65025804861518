import React, {Component} from "react";
import Loader1 from "../../Components/Loaders/Loader1/Loader1";
import {firestore} from "../../Firebase";
import firebase from "../../Firebase";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from "react-responsive-carousel";

import classes from "./Article.module.css";

class ArticleNew extends Component {
    state = {
        articlePostTimestamp: 0,
        articleID: 0,
        articleAuthorID: "",
        articleLinkByID: "",
        articleLinkByTitle: "",
        articleAuthorName: "",
        articleDatePublished: "",
        articlePreviewDescription: "",
        articlePreviewTitle: "",
        articlePreviewImageLink: "",
        isNews: false,
        isOpinion: false,
        isPodcast: false,
        isOnField: false,
        isApproved: false,
        fullArticle: [],
        linkPodcast: "",
    };

    componentDidMount() {
        this.getArticleId();
    }

    getArticleId = () => {
        let articleID = this.props.match.params.article_id;
        if (articleID != null && articleID !== "") {
            if (articleID.length > 5) {
                this.getArticleByTitleUrl(articleID);
            } else {
                this.setState({
                    articleID: articleID,
                });
                this.getArticleTheNewWay(articleID);
            }
        } else {
            this.props.history.push("/home");
        }
    };

    getArticleByTitleUrl = (titleURL) => {
        let fullTitleURL = "https://paulangelescu.ro/" + titleURL;
        console.log(fullTitleURL);
        firestore
            .collection("ALL_ARTICLES")
            .where("articleLinkByTitle", "==", fullTitleURL)
            .limit(1)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        this.setState({
                            fullArticle: doc.get("ARTICLE_BODY"),
                            articlePostTimestamp: doc.get("articlePostTimestamp"),
                            articleID: doc.get("articleID"),
                            articleAuthorID: doc.get("articleAuthorID"),
                            articleLinkByID: doc.get("articleLinkByID"),
                            articleLinkByTitle: doc.get("articleLinkByTitle"),
                            articleAuthorName: doc.get("articleAuthorName"),
                            articleDatePublished: doc.get("articleDatePublished"),
                            articlePreviewDescription: doc.get("articlePreviewDescription"),
                            articlePreviewTitle: doc.get("articlePreviewTitle"),
                            articlePreviewImageLink: doc.get("articlePreviewImageLink"),
                            isNews: doc.get("isNews"),
                            isOpinion: doc.get("isOpinion"),
                            isPodcast: doc.get("isPodcast"),
                            isOnField: doc.get("isOnField"),
                            isApproved: doc.get("isApproved"),
                            linkPodcast: doc.get("LINK_PODCAST"),
                        });
                        this.updateArticleStats(this.state.articleID);
                    } else {
                        //TODO REDIRECT TO 404
                        console.log("DID NOT FIND THE ARTICLE");
                    }
                });
            });
    };

    getArticleTheNewWay = (articleID) => {
        firestore
            .collection("ALL_ARTICLES")
            .doc("article_" + articleID)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    this.setState({
                        fullArticle: doc.get("ARTICLE_BODY"),
                        articlePostTimestamp: doc.get("articlePostTimestamp"),
                        articleID: doc.get("articleID"),
                        articleAuthorID: doc.get("articleAuthorID"),
                        articleLinkByID: doc.get("articleLinkByID"),
                        articleLinkByTitle: doc.get("articleLinkByTitle"),
                        articleAuthorName: doc.get("articleAuthorName"),
                        articleDatePublished: doc.get("articleDatePublished"),
                        articlePreviewDescription: doc.get("articlePreviewDescription"),
                        articlePreviewTitle: doc.get("articlePreviewTitle"),
                        articlePreviewImageLink: doc.get("articlePreviewImageLink"),
                        isNews: doc.get("isNews"),
                        isOpinion: doc.get("isOpinion"),
                        isPodcast: doc.get("isPodcast"),
                        isOnField: doc.get("isOnField"),
                        isApproved: doc.get("isApproved"),
                        linkPodcast: doc.get("LINK_PODCAST"),
                    });
                    this.updateArticleStats(this.state.articleID);
                } else {
                    //TODO REDIRECT TO 404
                }
            });
    };

    generateArticlePreview = () => {
        return (
            <React.Fragment>
                {this.state.articlePreviewImageLink != null && this.state.articlePreviewImageLink !== "" ?
                    <img className={classes.ArticlePreviewImage} src={this.state.articlePreviewImageLink}
                         alt="Article Featured"/> : null}
                <div className={classes.ArticleWrapper}></div>
                <div>
                    {this.state.articleDatePublished != null && this.state.articleDatePublished !== "" ? <span
                        className={[classes.Tag, classes.Date_tag].join(" ")}>{this.state.articleDatePublished}</span> : null}
                    {this.state.articleAuthorName != null && this.state.articleAuthorName !== "" ? <span
                        className={[classes.Tag, classes.Author_tag].join(" ")}>{this.state.articleAuthorName}</span> : null}
                    {this.state.isNews ?
                        <span className={[classes.Tag, classes.Economy_tag].join(" ")}>Știri</span> : null}
                    {this.state.isOpinion ?
                        <span className={[classes.Tag, classes.Justice_tag].join(" ")}>Opinii</span> : null}
                    {/* {this.state.isAgro ? <span className={[classes.Tag, classes.Agro_tag].join(" ")}>AGRO</span> : null} */}
                    {this.state.isPodcast ?
                        <span className={[classes.Tag, classes.Tech_tag].join(" ")}>Podcast</span> : null}
                    {this.state.isOnField ?
                        <span className={[classes.Tag, classes.Travel_tag].join(" ")}>#peteren</span> : null}
                    <p className={classes.ArticleShortDescription}>{this.state.articlePreviewDescription}</p>
                </div>
            </React.Fragment>
        );
    };

    loadArticleBodyWithID = (articleID) => {
        console.log("queryFullArticle: " + articleID + " number of el: " + this.state.articleNumberOfElements);
        let articleNumberOfElements = this.state.articleNumberOfElements;
        let articleServerID = "article_" + articleID;
        for (let i = 0; i <= articleNumberOfElements; i++) {
            firestore
                .collection("ARTICLES")
                .doc(articleServerID)
                .collection("RO")
                .doc("element_" + i)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        var elementType = doc.data().type;
                        var elementData = doc.data().content;
                        var elementLink = doc.data().link;
                        if (elementType == null) {
                            elementType = "FATAL ERROR";
                        }
                        if (elementData == null) {
                            elementData = "FATAL ERROR";
                        }
                        if (elementLink == null) {
                            elementLink = "FATAL ERROR";
                        }
                        this.addElementToArticleWithID(articleID, elementType, elementData, elementLink, i);
                    }
                });
        }
    };

    addElementToArticleWithID = (articleID, elementType, elementData, elementLink, elementNumber) => {
        //console.log("addElementToArticleWithID ADDING ELEMENT TO ARTICLE WITH ID: " + articleID + " ELEMENT TYPE: " + elementType + " ELEMENT DATA: " + elementData + " ELEMENT LINK: " + elementLink);
        let prevArticleData = [...this.state.fullArticle];
        prevArticleData.push({
            elementType: elementType,
            elementData: elementData,
            elementLink: elementLink,
            elementNumber: elementNumber
        }); //fullArticle: [{ elementType: "image", elementData: "link" }],
        this.setState({
            fullArticle: prevArticleData,
        });
        if (elementNumber === this.state.articleNumberOfElements - 1) {
            this.setState({
                articleLoaded: true,
            });
        }

        console.log(this.state.fullArticle);
    };

    generateArticleBody = () => {
        return this.state.fullArticle.length > 0 ? (
            <React.Fragment>
                <div>
                    {this.state.fullArticle.map((articleElement) => {
                        console.log("MAPPING: " + articleElement);
                        return this.generateArticleElementHTML(articleElement, this.state.articleID);
                    })}
                </div>
                <br/>
                <button
                    className={classes.simpleButton}
                    onClick={() => {
                        navigator.clipboard.writeText(this.state.articleLinkByTitle);
                        alert("Copiat cu succes!");
                    }}
                >
                    Copiază link-ul articolului
                </button>
            </React.Fragment>
        ) : (
            <Loader1/>
        );
    };

    generateArticleElementHTML = (e, articleID) => {
        if (e.elementType === "title" && e.elementData != null && e.elementData !== "") {
            return (
                <h2 className={classes.ArticleTitle} key={"article_title_element_" + articleID + e.elementNumber}>
                    {e.elementData}
                </h2>
            ); //<div key={"article_" + articleID + "element_" + e.elementNumber}>TITLE: {e.elementData}</div>
        } else if (e.elementType === "text" && e.elementData != null && e.elementData !== "") {
            return (
                <p className={classes.ArticleParagraph} key={"article_text_element_" + articleID + e.elementNumber}>
                    {e.elementData}
                </p>
            ); //<div key={"article_" + articleID + "element_" + e.elementNumber}>TEXT: {e.elementData}</div>
        } else if (e.elementType === "image" && e.elementLink != null && e.elementLink !== "") {
            return <img className={classes.ArticlePreviewImage} src={e.elementLink}
                        key={"article_image_element_" + articleID + e.elementNumber} alt="Article Body"/>; // <div key={"article_" + articleID + "element_" + e.elementNumber}>IMAGE</div>
        } else if (e.elementType === "youtube" && e.elementData != null && e.elementData !== "") {
            if (window.innerWidth > 600) {
                return (
                    <div key={"article_youtube_element_" + articleID + e.elementNumber}>
                        <iframe width={window.innerWidth * 0.6} height={window.innerWidth * 0.4}
                                src={"https://www.youtube.com/embed/" + e.elementData} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                );
            } else {
                return (
                    <div key={"article_youtube_element_" + articleID + e.elementNumber}>
                        <iframe width={window.innerWidth - 20} height={window.innerWidth * 0.6}
                                src={"https://www.youtube.com/embed/" + e.elementData} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                );
            }

            //e.elementData
            //
        } else if (e.elementType === "link" && e.elementLink != null && e.elementLink !== "") {
            return (
                <p className={classes.ArticleParagraph} key={"article_text_element_" + articleID + e.elementNumber}>
                    <a href={e.elementLink} target="_blank" rel="noopener noreferrer">
                        {e.elementData}
                    </a>
                </p>
            );
        } else if (e.elementType === "slideshow" && e.elementLink != null && e.elementLink !== "") { //the carousel of images
            // console.log("elementLink: " + e.elementLink);
            // console.log("elementData: " + e.elementData);
            // //TODO SPLIT THE CAPTIONS AND THE IMAGE LINKS
            // let slideshowImage = e.elementLink.split("*");
            // let slideshowCaption = e.elementData.split("*");
            //
            // let slideshowMap = []; //: [{ slideImage: "image", slideCaption: "link" }],
            // for (let i = 0; i < slideshowImage.length; i++) {
            //     slideshowMap.push({slideImage: slideshowImage[i], slideCaption: slideshowCaption[i]});
            // }
            // return (
            //     <section className={classes.FeaturesWrapperPrimaryColor}>
            //         <div className={classes.CarouselDiv}>
            //             <Carousel>
            //                 {slideshowMap.map((slideshowElement) => {
            //                     return (
            //                         <div>
            //                             <img src={slideshowElement.slideImage}/>
            //                             {slideshowElement.slideCaption != null && slideshowElement.slideCaption !== "" ?
            //                                 <p className="legend">{slideshowElement.slideCaption}</p> : null}
            //                         </div>
            //                     );
            //                 })}
            //             </Carousel>
            //         </div>
            //     </section>
            // );
        }
    };

    updateArticleStats = (articleID) => {
        const increment = firebase.firestore.FieldValue.increment(1);
        firestore
            .collection("COUNTER")
            .doc("article_" + articleID)
            .update("INDIVIDUAL", increment);
    };

    generateArticlePage = () => {
        return (
            <section className={classes.FeaturesWrapperPrimaryColor}>
                {this.generateArticlePreview()}
                <hr/>
                {this.generateArticleBody()}
            </section>
        );
    };

    render() {
        return this.generateArticlePage();
    }
}

export default ArticleNew;
